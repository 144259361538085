<template>
    <van-popup v-model="visible" class="export-popup" position="bottom">
        <van-form @submit="onSubmit">
            <van-field
                v-model="form.title"
                name="title"
                label="标题"
                placeholder="请输入讲章标题"
                clearable
            ></van-field>
            <van-field
                readonly
                clickable
                name="calendar"
                v-model="form.speech_at"
                label="时间"
                placeholder="请选择时间"
                @click="showCalendar = true"
                clearable
            ></van-field>
            <van-calendar v-model="showCalendar" @confirm="onSpeechConfirm"></van-calendar>
            <van-field
                v-model="form.location"
                name="location"
                label="地点"
                placeholder="请输入地点"
                clearable
            ></van-field>
            <van-field
                readonly
                clickable
                name="category_id"
                :value="sCatName"
                label="分类"
                placeholder="请选择分类"
                @click="showPicker = true"
                clearable
            ></van-field>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onCategoryConfirm"
                    @cancel="showPicker = false"
                    :default-index="defaultIndex"
                ></van-picker>
            </van-popup>
            <div style="margin: 16px;">
                <van-button
                    round
                    block
                    type="info"
                    native-type="submit"
                    :loading="submitting"
                    loading-size="16px"
                    loading-text="导出中..."
                    :disabled="submitting"
                >导出</van-button>
            </div>
        </van-form>
    </van-popup>
</template>

<script>
import { getCategories } from '@/api/category'
import { exportSermons } from '@/api/sermon'
import FileSaver from 'file-saver'

export default {
    name: 'Export',
    data () {
        return {
            visible: false,
            defaultIndex: 0,
            submitting: false,
            showPicker: false,
            showCalendar: false,
            form: {
                title: null,
                location: null,
                speech_at: null,
                category_id: null
            },
            categories: [],
            sCatName: null
        }
    },
    computed: {
        columns () {
            const need = []
            this.categories.map(cat => need.push(cat.name))
            return need
        }
    },
    methods: {
        onSubmit () {
            this.submitting = true
            exportSermons(this.form).then(res => {
                FileSaver.saveAs(res.data.download_url, res.data.download_url.split('/').pop());
                this.submitting = false
            }).catch(() => {
                this.$notify({ type: 'error', message: '导出失败, 请稍后重试.' })
                this.submitting = false
            })
        },
        show () {
            this.visible = true
        },
        onCategoryConfirm (value, index) {
            this.form.category_id = this.categories[index].id
            this.sCatName = this.categories[index].name
            this.showPicker = false
        },
        onSpeechConfirm (date) {
            const m = date.getMonth() + 1
            this.form.speech_at = `${date.getFullYear()}-${m < 10 ? '0' + m : m}-${date.getDate()}`
            this.showCalendar = false
        }
    },
    created() {
        getCategories({ type: 'sermon' }).then(res => {
            this.categories = res.data
        })
    }
};
</script>

<style lang="less">
    .export-popup {
        padding: 15px;
        box-sizing: border-box;
    }
</style>
