<template>
    <van-cell :title="sermon.title">
        <template #default>
            <van-icon class="action-icon" name="edit" @click="onEdit" />
            <van-icon class="action-icon" name="delete" @click="onDelete"></van-icon>
        </template>
        <template #label>
            {{ sermon.category.name }} · {{ sermon.location }} · {{ sermon.speech_at }}
            <van-action-sheet
                @select="handleSelect"
                :round="false"
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                description="确定删除吗?"
                @cancel="() => this.show = false"
            ></van-action-sheet>
        </template>
    </van-cell>
</template>

<script>
import { deleteSermons } from '@/api/sermon'

export default {
    name: 'SermonItem',
    props: {
        sermon: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            show: false,
            loading: false
        }
    },
    computed: {
        actions () {
            return [
                {
                    name: '确定',
                    loading: this.loading
                }
            ]
        }
    },
    methods: {
        onDelete () {
            this.show = true
        },
        onEdit () {
            this.$router.push({ path: `/sermon/${this.sermon.id}/edit` })
        },
        handleSelect () {
            this.loading = true
            deleteSermons(this.sermon.id).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$emit('delete', this.sermon)
            }).finally(() => {
                this.show = false
                this.loading = false
            })
        }
    }
};
</script>

<style lang="less">
    .action-icon {
        margin-left: 5px;
    }
</style>
