<template>
    <van-popup v-model="visible" class="export-popup" position="bottom">
        <van-form @submit="onSubmit">
            <van-field
                v-model="form.title"
                name="title"
                label="标题"
                placeholder="请输入讲章标题"
                clearable
            ></van-field>
            <van-field
                readonly
                clickable
                name="calendar"
                v-model="form.speech_at"
                label="时间"
                placeholder="请选择时间"
                @click="showCalendar = true"
                clearable
            ></van-field>
            <van-calendar v-model="showCalendar" :min-date="minDate" @confirm="onSpeechConfirm"></van-calendar>
            <van-field
                v-model="form.location"
                name="location"
                label="地点"
                placeholder="请输入地点"
                clearable
            ></van-field>
            <van-field
                readonly
                clickable
                name="category_id"
                :value="sCatName"
                label="分类"
                placeholder="请选择分类"
                @click="showPicker = true"
                clearable
            ></van-field>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onCategoryConfirm"
                    @cancel="showPicker = false"
                    :default-index="defaultIndex"
                ></van-picker>
            </van-popup>
            <div class="btn-area">
                <van-button
                    round
                    block
                    type="info"
                    native-type="submit"
                    :loading="submitting"
                    loading-size="16px"
                    loading-text="导出中..."
                    :disabled="submitting"
                >搜索</van-button>
                <van-button
                    round
                    block
                    type="info"
                    @click.prevent="onReset"
                    :loading="submitting"
                    loading-size="16px"
                    loading-text="导出中..."
                    :disabled="submitting"
                >重置</van-button>
            </div>
        </van-form>
    </van-popup>
</template>

<script>
import { getCategories } from '@/api/category'

export default {
    name: 'Search',
    data () {
        return {
            minDate: new Date(2000, 0, 1),
            visible: false,
            defaultIndex: 0,
            submitting: false,
            showPicker: false,
            showCalendar: false,
            form: {
                title: null,
                location: null,
                speech_at: null,
                category_id: null
            },
            categories: [],
            sCatName: null
        }
    },
    computed: {
        columns () {
            const need = []
            this.categories.map(cat => need.push(cat.name))
            return need
        }
    },
    methods: {
        onReset () {
            this.$emit('reset', {})
            this.visible = false
        },
        onSubmit () {
            this.$emit('ok', this.form)
            this.visible = false
        },
        show () {
            this.visible = true
        },
        onCategoryConfirm (value, index) {
            this.form.category_id = this.categories[index].id
            this.sCatName = this.categories[index].name
            this.showPicker = false
        },
        onSpeechConfirm (date) {
            const m = date.getMonth() + 1
            const d = date.getDate()
            this.form.speech_at = `${date.getFullYear()}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
            this.showCalendar = false
        }
    },
    created() {
        getCategories({ type: 'sermon' }).then(res => {
            this.categories = res.data
        })
    }
};
</script>

<style lang="less">
    .export-popup {
        padding: 15px;
        box-sizing: border-box;
    }
    .btn-area {
        margin: 16px;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;

        .van-button {
            height: 36px;
            line-height: 36px;
            width: 45%;
        }
    }
</style>
