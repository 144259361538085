<template>
    <div>
        <my-image src="https://file4.mogody.com/avatars/XdIQLSb7hysluigM.jpg" />
        <van-list
            class="sermon-list"
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            :error.sync="error"
            @load="onLoad"
            error-text="加载,点击重新加载"
            ref="list"
        >
            <sermon-item @delete="handleDelete" :key="data.id" v-for="data in dataset.data" :sermon="data"></sermon-item>
        </van-list>

        <van-row type="flex" class="action-btns">
            <van-col span="4">
                <van-button type="info" icon="search" class="custom-btn" @click="onSearch"></van-button>
            </van-col>
            <van-col span="4">
                <van-button type="info" icon="edit" class="custom-btn" to="/sermon/create"></van-button>
            </van-col>
            <van-col span="4">
                <van-button type="info" icon="down" class="custom-btn" @click="onExport"></van-button>
            </van-col>
        </van-row>
        <export ref="exporter" />
        <search @ok="handleSearch" @reset="handleSearch" ref="searcher" />
    </div>
</template>

<script>
import { getSermons } from '@/api/sermon'
import SermonItem from './SermonItem'
import MyImage from '@/components/MyImage'
import Export from './Export'
import Search from './Search'

export default {
    components: { SermonItem, MyImage, Export, Search },
    name: 'SermonIndex',
    data () {
        return {
            params: {},
            listLoading: false,
            listFinished: false,
            error: false,
            dataset: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            }
        }
    },
    methods: {
        handleSearch (params = {}) {
            this.params = params
            this.onRefresh()
        },
        onRefresh () {
            this.dataset.data = []
            this.dataset.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        onSearch () {
            this.$refs.searcher.show()
        },
        onExport () {
            this.$refs.exporter.show()
        },
        handleDelete (s) {
            this.dataset.data = this.dataset.data.filter(i => i.id !== s.id)
        },
        onLoad () {
            const params = Object.assign(this.params, {
                page: this.dataset.meta.current_page + 1
            })
            getSermons(params).then(res => {
                res.data.unshift(...this.dataset.data)
                this.dataset = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    },
};
</script>

<style lang="less">
    .sermon-list {
        .van-cell__title {
            flex-grow: 4;
        }
        .van-cell__value {
            flex-grow: 1;
        }
    }
    .action-btns {
        padding: 24px 16px;
        flex-direction: column;
        align-items: flex-end;
        position: fixed;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;

        .custom-btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 0px;
            margin-top: 10px;
            line-height: inherit;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
        }
        .van-col {
            text-align: right;
        }
    }
</style>
